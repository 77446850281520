const Header = ()=>{

    return(
        <>
            <header>
                
            </header>
        </>
    )



}

export default Header;