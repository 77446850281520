import React from 'react'
import Title from '../components/Title'

const NoMatch = () => {
  return (
    <div>
        <Title headline="No Match Found, ya Donkey"/>
        <p>Der er desværre ingen match på den side, du forsøger at få adgang til.</p>


      {/*Link til forsiden*/}


    </div>
  )
}

export default NoMatch